
    import TimelineXrItem from "@/components/timeline_items/timeline_xr_item.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            TimelineXrItem
        },
    })

    // *****************************************************************************************************************
    export default class XrTimeline extends Vue {
        // Data function
        public data() {
            return {
                appleGlassesImagePathAndName:
                    require("@/assets/images/apple_glasses.png"),
                facebookGlassesImagePathAndName:
                    require("@/assets/images/facebook_glasses.png"),
                fiveGImagePathAndName:
                    require("@/assets/images/5g.png"),
                gearIconPathandName:
                    require("@/assets/images/icon_gear.png"),
                headsetIconPathAndName:
                    require("@/assets/images/icon_headset.png"),
                hololens3ImagePathAndName:
                    require("@/assets/images/hololens_3.png"),
                magicLeapImagePathAndName:
                    require("@/assets/images/magic_leap.png"),
                oculusQuestImagePathAndName:
                    require("@/assets/images/oculus_quest_2.png"),
                playstationVr2ImagePathAndName:
                    require("@/assets/images/playstation_vr_2.png"),
                playstationVrImagePathAndName:
                    require("@/assets/images/playstation_vr.png"),
                usbCImagePathAndName:
                    require("@/assets/images/usb_c.png"),
                waveOpticsGlassesImagePathAndName:
                    require("@/assets/images/waveoptics_glasses.png"),
            };
        }
    }
